<template>
  <div>
    <title-bar :title="$t('我的账号')" />
    <h2 class="block-title">{{ $t("当前账号信息") }}</h2>
    <van-form @submit="onSubmit" class="form">
      <van-field v-model="data.mobile" readonly :label="$t('电话')" />
      <van-field
        v-model="data.nickname"
        :label="$t('昵称')"
        :placeholder="$t('请输入昵称')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="data.password"
        type="password"
        :label="$t('密码')"
        :placeholder="$t('不修改请留空')"
      />
      <div style="margin: 16px">
        <van-button
          round
          block
          :loading="loading"
          type="info"
          native-type="submit"
          >{{ $t("更新") }}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Button } from "vant";
import TitleBar from "../../components/title-bar.vue";

export default {
  components: {
    TitleBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },

  data() {
    return {
      loading: false,
      data: {
        mobile: "",
        nickname: "",
        password: "",
      },
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      this.loading = true;
      let res = await this.$userApi.customer.getSelfInfo();
      this.loading = false;
      if (res.status == 200) {
        this.data = res.data;
      }
    },
    async onSubmit() {
      this.loading = true;
      try {
        let res = await this.$userApi.customer.updateSelfInfo(this.data);
        if (res.status == 200) {
          this.$toast.success(this.$t("更新成功"));
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>